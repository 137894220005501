// import '@babel/polyfill';
import Vue from "vue";
// import MultiFiltersPlugin from './plugins/MultiFilters';
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import './registerServiceWorker';

import "@mdi/font/css/materialdesignicons.css";

import truncate from 'vue-truncate-collapsed';

import router from "./router";
import store from "./store/index";
import Vuebar from "vuebar";

import "./permission";
import "./plugins/base";
import "./plugins/google-places";
import "./plugins/phone-number-input";
import "./plugins/luxon"
import "./plugins/lodash"
import "./plugins/firebase"

// Vue.use(MultiFiltersPlugin);

import VueSkycons from "vue-skycons";

Vue.use(VueSkycons, {
    color: "#172465",
});

Vue.config.productionTip = false;
Vue.use(Vuebar);

import "@/scss/main.scss";

import {
    createProvider
} from './vue-apollo'

import './registerServiceWorker'

new Vue({
    components: {
        'truncate': truncate
    },
    vuetify,
    store,
    router,
    apolloProvider: createProvider(),
    render: (h) => h(App)
}).$mount("#app");