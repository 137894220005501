const getters = {
  apiUrl: state => state.app.apiUrl,
  strapi: state => state.app.strapi,
  user: state => state.auth.user,
  role: state => (state.auth.user ? state.auth.user.role : undefined),
  // 
  isAdmin: state => (state.auth.user && state.auth.user.role ? state.auth.user.role.name == "Admin" : false),
  isClientAdmin: state => (state.auth.user && state.auth.user.role ? state.auth.user.role.name == "ClientAdmin" : false),
  isClientUser: state => (state.auth.user && state.auth.user.role ? state.auth.user.role.name == "ClientUser" : false),
  isDriver: state => (state.auth.user && state.auth.user.role ? state.auth.user.role.name == "Driver" : false),
  // tempContract: state => state.contracts.tempContract
};
export default getters;