import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/vuetify/overrides.scss'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify);

const theme = {
    primary: "#3E688A",
    info: "#3E688A",
    success: "#21c1d6",
    accent: "#fc4b6c",
    default: "#563dea",
};

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
});
