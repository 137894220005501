const TokenKey = "jwt";

export function getToken() {
  const token = JSON.parse(localStorage.getItem(TokenKey));
  return token;
}
export function setToken(token) {
  // console.log("setToken");
  return localStorage.setItem(TokenKey, JSON.stringify(token));
}
export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
