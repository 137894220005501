import Vue from 'vue';

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
    serverZone: 'utc',
    serverFormat: 'iso',
    clientZone: 'America/New_York',
    clientFormat: 'locale',
    localeLang: 'en',
    localeFormat: 'med' // see localeFormat below
//     diffForHumans: {} // see diffForHumans below
//     i18n: {} // see i18n below
});