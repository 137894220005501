<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  watch: {
    user: function (value) {
      if (!value) {
        this.$nextTick(() => {
          location.reload();
        });
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
