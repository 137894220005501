import Strapi from 'strapi-sdk-javascript';
// import themeConfig from '../../../themeConfig.js';
// console.log('process.env.API_URL', process.env.API_URL);
//const apiUrl = process.env.API_URL || 'https://backend.meddrive.health';
const apiUrl = process.env.API_URL || 'http://localhost:1948';
console.log(apiUrl);
const strapi = new Strapi(apiUrl);
export default {
    namespaced: true,
    state: {
        strapi,
        apiUrl,
        // theme: themeConfig.theme,
        // isSidebarActive: false,
        // isSidebarReduced: themeConfig.sidebarCollapsed,
        // sidebarWidth: 'default',
        // themeColor: themeConfig.themeColor,
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: "white",
        SidebarBg: "",
    },
    mutations: {
        // IS_SIDEBAR_ACTIVE(state, value) {
        //   state.isSidebarActive = value;
        // },
        // TOGGLE_REDUCE_SIDEBAR(state, val) {
        //   state.isSidebarReduced = val;
        // },
        // SET_THEME(state, val) {
        //   state.theme = val;
        // },
        // SET_THEME_COLOR(state, val) {
        //   state.themeColor = val;
        // },
        // SET_SIDEBAR_WIDTH(state, width) {
        //   state.sidebarWidth = width;
        // }
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
    },
    actions: {
        // updateSidebarWidth({ commit }, width) {
        //   commit('SET_SIDEBAR_WIDTH', width);
        // },
        async sendMessage({ state }, data) {
            const response = await state.strapi.createEntry("messages", data);
            return response;
        },
        async sendReview({ state }, data) {
            const response = await state.strapi.createEntry("reviews", data);
            return response;
        },
        async getReviews({ state }) {
            const response = await state.strapi.getEntries("reviews");
            return response;
        },
        // async sendySubscribe({ state }, data) {
        //   const response = await state.strapi.request(
        //     'GET',
        //     `/sendy-subscribe?email=${data.email}`
        //   );
        //   return response;
        // },
        // async getLandingData({ state }, name) {
        //   state.strapi.clearToken();
        //   const response = await state.strapi.getEntries('landings', { name });
        //   return response;
        // }
    },
};
