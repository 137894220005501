import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth';
const state = {
  token: getToken(),
  user: undefined,
  id: -1,
  role: undefined
};
const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  }
};
const actions = {
  facebookLogin({
    rootGetters,
    commit,
    dispatch
  }, authResponse) {
    return new Promise((resolve, reject) => {
      authResponse.access_token = authResponse.accessToken;
      const url = Object.keys(authResponse)
        .map(function (k) {
          return (
            encodeURIComponent(k) + '=' + encodeURIComponent(authResponse[k])
          );
        })
        .join('&');
      rootGetters.strapi.clearToken();
      rootGetters.strapi
        .request('get', '/auth/facebook/callback?' + url)
        .then(response => {
          if (!response.user.activated) {
            reject('account is disabled');
            return;
          }

          commit('SET_USER', [response.user]);
          commit('SET_TOKEN', response.jwt);
          commit('SET_ID', response.user.id);
          commit('SET_ROLE', response.user.role.name);
          setToken(response.jwt);
          dispatch('changeRoles');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  googleLogin({
    rootGetters,
    commit
  }, GoogleUser) {
    if (GoogleUser && GoogleUser.tc && GoogleUser.tc.access_token) {
      return new Promise((resolve, reject) => {
        const tc = GoogleUser.tc;
        const url = Object.keys(tc)
          .map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(tc[k]);
          })
          .join('&');
        rootGetters.strapi.clearToken();
        rootGetters.strapi
          .request('get', '/auth/google/callback?' + url)
          .then(response => {
            if (!response.user.activated) {
              reject('account is disabled');
              return;
            }

            commit('SET_USER', response.user);
            commit('SET_TOKEN', response.jwt);
            commit('SET_ID', response.user.id);
            commit('SET_ROLE', response.user.role.name);
            setToken(response.jwt);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  forgotPassword({
    rootGetters
  }, userInfo) {
    const {
      email
    } = userInfo;
    return new Promise((resolve, reject) => {
      rootGetters.strapi.clearToken();
      rootGetters.strapi
        .forgotPassword(email.trim(), rootGetters.apiUrl + '/reset-password')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  changePassword({
    state,
    rootGetters,
    dispatch
  }, userInfo) {
    const {
      password
    } = userInfo;
    return new Promise((resolve, reject) => {
      const code = Math.random()
        .toString(36)
        .substring(7);
      rootGetters.strapi.updateEntry('users', parseInt(state.id), {
        resetPasswordToken: code
      });
      rootGetters.strapi
        .resetPassword(code, password, password)
        .then(async response => {
          await dispatch('logout');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resetPassword({
    rootGetters
  }, userInfo) {
    const {
      code,
      password
    } = userInfo;
    // console.log(userInfo);
    return new Promise((resolve, reject) => {
      rootGetters.strapi.clearToken();
      rootGetters.strapi
        .resetPassword(code, password, password)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  login({
    rootGetters,
    commit
  }, userInfo) {
    // console.log(userInfo);
    const {
      username,
      password,
      rememberme
    } = userInfo;
    return new Promise((resolve, reject) => {
      rootGetters.strapi.clearToken();
      rootGetters.strapi
        .login(username.trim().toLowerCase(), password)
        .then(async response => {
          if (!response.user.confirmed) {
            reject('email not confirmed');
            return;
          }
          if (!response.user.activated) {
            reject('account is disabled');
            return;
          }

          if (rememberme) {
            setToken(response.jwt);
          } else {
            removeToken();
          }

          rootGetters.strapi.setToken(response.jwt);
          commit('SET_TOKEN', response.jwt);
          commit('SET_ID', response.user.id);
          commit('SET_USER', response.user);
          commit('SET_ROLE', response.user.role.name);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  register({
    rootGetters,
    commit
  }, userInfo) {
    const {
      username,
      password
    } = userInfo;
    return new Promise((resolve, reject) => {
      rootGetters.strapi.clearToken();
      rootGetters.strapi
        .register(
          username.trim().toLowerCase(),
          username.trim().toLowerCase(),
          password
        )
        .then(async response => {
          rootGetters.strapi.setToken(response.jwt);
          setToken(response.jwt);
          commit('SET_USER', response.user);
          commit('SET_TOKEN', response.jwt);
          commit('SET_ROLE', response.user.role.name);
          commit('SET_ID', response.user.id);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getInfo({
    rootGetters,
    commit,
    dispatch,
    state
  }) {
    if (state.token) {
      return new Promise((resolve, reject) => {
        rootGetters.strapi
          .request('get', '/users/me')
          .then(async response => {
            if (!response) {
              reject('Verification failed, please Login again.');
            }
            if (!response.role || !response.role.name) {
              reject('getInfo: roles must be assigned!');
            }

            if (!response.activated) {
              reject('account is disabled');
              return;
            }

            commit('SET_ID', response.id);
            commit('SET_USER', response);
            commit('SET_ROLE', response.role.name);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            dispatch('logout');
          });
      });
    } else {
      dispatch('logout');
    }
  },
  updateProfile({
    rootGetters,
    commit,
    state
  }, data) {
    const postData = data;
    // console.log(data);
    return new Promise((resolve, reject) => {
      rootGetters.strapi
        .updateEntry('users', state.id, postData)
        .then(async response => {
          await commit('SET_USER', response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  logout({
    rootGetters,
    commit
  }) {
    return new Promise(resolve => {
      removeToken();
      commit('SET_TOKEN', '');
      commit('SET_ROLE', undefined);
      commit('SET_USER', undefined);
      commit('SET_ID', -1);
      rootGetters.strapi.clearToken();
      resolve();
    });
  },
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLE', undefined);
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};