import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
    if (store.getters.user) {
        next();
        return;
    }
    next("/");
};
const ifAuthenticatedAndFitRole = (to, from, next) => {
    if (
        store.getters.user &&
        store.getters.role &&
        (!to.meta ||
            !to.meta.roles ||
            to.meta.roles.includes(store.getters.role.name))
    ) {
        next();
        return;
    }
    next("/");
};
// const ifAuthenticatedAndNormalUser = (to, from, next) => {
//   if (!store.getters.user) {
//     next("/login");
//     return;
//   }
//   if (
//     store.getters.user.confirmed &&
//     store.getters.role &&
//     store.getters.role.name == "Authenticated"
//   ) {
//     next();
//     return;
//   } else {
//     next("/register");
//   }
// };

const onLogout = async (to, from, next) => {
    await store.dispatch("auth/logout");
    next("/");
};

export default new Router({
    mode: "history",
    base: process.env.BASE_URL || 'https://meddrive.health',
    // This is for the scroll top when click on any router link
    // scrollBehavior: (to, from, savedPosition) => {
    // let scrollTo = 0;

    // if (to.hash) {
    //   scrollTo = to.hash;
    // } else if (savedPosition) {
    //   scrollTo = savedPosition.y;
    // }

    // return goTo(scrollTo);
    // },
    // This is for the scroll top when click on any router link
    routes: [
        {
            path: "/dashboard/",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Dashboard",
                    path: "view",
                    component: () => import("@/views/dashboard/index"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "CalendarPage",
                    path: "calendar",
                    component: () => import("@/views/admin/CalendarMonthly.vue"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "OrdersPage",
                    path: "orders",
                    component: () => import("@/views/admin/AvailableOrdersPage.vue"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "PatientsPage",
                    path: "patients",
                    component: () => import("@/views/admin/PatientsPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "DestinationsPage",
                    path: "destinations",
                    component: () => import("@/views/admin/DestinationsPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "PayrollPage",
                    path: "payroll",
                    component: () => import("@/views/admin/PayrollPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "AccountingPage",
                    path: "accounting",
                    component: () => import("@/views/admin/AccountingPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "CustomersPage",
                    path: "clients",
                    component: () => import("@/views/admin/CustomersPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                    meta: {
                        roles: ["Admin"],
                    },
                },
                {
                    name: "UsersPage",
                    path: "users",
                    component: () => import("@/views/admin/UsersPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                    meta: {
                        roles: ["Admin", "ClientAdmin"],
                    },
                },
                {
                    name: "NotificationsPage",
                    path: "notifications",
                    component: () => import("@/views/admin/NotificationsPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                    meta: {
                        roles: ["Admin", "ClientAdmin", "Driver", "ClientUser"],
                    },
                },
                {
                    name: "AvailableOrdersPage",
                    path: "available-orders",
                    component: () => import("@/views/admin/AvailableOrdersPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "ChatPage",
                    path: "chat",
                    component: () => import("@/views/apps/chat/Chat"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                },
                {
                    name: "SettingsPage",
                    path: "settings",
                    component: () => import("@/views/admin/SettingsPage"),
                    beforeEnter: ifAuthenticatedAndFitRole,
                    meta: {
                        roles: ["Admin"],
                    },
                },
            ],
        },
        {
            path: "/",
            component: () => import("@/layouts/public-layout/Layout"),
            children: [
                {
                    name: "HomePage",
                    path: "",
                    component: () => import("@/views/HomePage"),
                },
                {
                    name: "JoinOurFleet",
                    path: "join-our-fleet",
                    component: () => import("@/views/JoinOurFleet"),
                },
                /* {
                    name: "RideWithUs",
                    path: "ride-with-us",
                    component: () => import("@/views/RideWithUs"),
                }, */
                {
                    name: "RideForFamily",
                    path: "rides-for-family-members",
                    component: () => import("@/views/RideForFamily"),
                },
                {
                    name: "RideForProviders",
                    path: "rides-for-healthcare-providers",
                    component: () => import("@/views/RideForProviders"),
                },
                {
                    name: "ProfilePage",
                    path: "profile",
                    component: () => import("@/views/ProfilePage"),
                },
                {
                    name: "WhoWeArePage",
                    path: "who-we-are",
                    component: () => import("@/views/WhoWeArePage"),
                },
                {
                    name: "PrivacyPolicyPage",
                    path: "privacy-policy",
                    component: () => import("@/views/PrivacyPolicyPage"),
                },
                {
                    name: "TermsOfServicePage",
                    path: "terms-of-service",
                    component: () => import("@/views/TermsOfServicePage"),
                },
                {
                    name: "Error",
                    path: "error",
                    component: () => import("@/components/authentication/Error"),
                },
                {
                    name: "Login",
                    path: "login",
                    component: () => import("@/components/authentication/Login"),
                },
                {
                    name: "Register",
                    path: "register",
                    component: () => import("@/components/authentication/Register"),
                },
                {
                    name: "ForgotPassword",
                    path: "forgot-password",
                    component: () => import("@/components/authentication/ForgotPassword"),
                },
                {
                    name: "ResetPassword",
                    path: "reset-password",
                    component: () => import("@/components/authentication/ResetPassword"),
                },
                {
                    name: "Error404",
                    path: "error404",
                    component: () => import("./views/Error404.vue"),
                },
            ],
        },
        {
            path: "/logout",
            beforeEnter: onLogout,
        },
        {
            path: "*",
            redirect: "/Error404",
        },
    ],
});
