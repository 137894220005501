import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

var firebaseConfig = {
  apiKey: "AIzaSyA5yG1GEuDMOm46i7cjUXmsXnvSxO0DxKs",
  authDomain: "meddrive-f4a40.firebaseapp.com",
  databaseURL: "https://meddrive-f4a40.firebaseio.com",
  projectId: "meddrive-f4a40",
  storageBucket: "meddrive-f4a40.appspot.com",
  messagingSenderId: "250169695274",
  appId: "1:250169695274:web:8363c689cb87fed30d3753",
  measurementId: "G-XF75WLZCV5",
};

Promise.resolve()
  .then(() =>
    Vue.loadScript("https://www.gstatic.com/firebasejs/8.2.1/firebase-app.js")
  )
  .then(() =>
    Vue.loadScript(
      "https://www.gstatic.com/firebasejs/8.2.1/firebase-messaging.js"
    )
  )
  .then(() =>
    Vue.loadScript(
      "https://www.gstatic.com/firebasejs/8.2.1/firebase-analytics.js"
    )
  )
  .then(() => {
    window.firebase.initializeApp(firebaseConfig);
    const messaging = window.firebase.messaging();
    window.firebase.analytics();

    messaging.onTokenRefresh(function() {
      messaging.getToken().then(function(refreshedToken) {
        // console.log("refreshedToken", refreshedToken);
        //  ... // отправить новый токен на сервер
        // TODO: handle
      });
    });

    // self.addEventListener("notificationclick", function(event) {
    //   console.log("notificationclick");
    //   const target = event.notification.data.click_action;
    //   event.notification.close();
    //   event.waitUntil(
    //     clients
    //       .matchAll({
    //         type: "window",
    //         includeUncontrolled: true,
    //       })
    //       .then(function(clientList) {
    //         for (var i = 0; i < clientList.length; i++) {
    //           var client = clientList[i];
    //           console.log(client.url, client.focus);
    //           if (client.url === target && "focus" in client) {
    //             return client.focus();
    //           }
    //         }
    //         return clients.openWindow(target);
    //       })
    //   );
    // });

    const play = () => {
      try {
        const context = new AudioContext();
        window
          .fetch("https://meddrive.health/sound/notification.mp3")
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
          .then((audioBuffer) => {
            const source = context.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(context.destination);
            source.start();
          });
      } catch (ex) {
        console.log(ex);
      }
    };

    messaging.onMessage(function(payload) {
      // console.log("payload", payload);
      play();
      navigator.serviceWorker.register("/firebase-messaging-sw.js");
      Notification.requestPermission(function(result) {
        if (result === "granted") {
          navigator.serviceWorker.ready
            .then(function(registration) {
              const data = { ...payload.notification, ...payload.data };
              const notificationTitle = data.title;
              const notificationOptions = {
                body: data.body,
                icon: data.icon,
                image: data.image,
                click_action: data.click_action,
                requireInteraction: true,
                data,
              };
              return registration.showNotification(
                notificationTitle,
                notificationOptions
              );
            })
            .catch(function(error) {
              console.log("ServiceWorker registration failed", error);
            });
        }
      });
    });
  });
