import router from './router';
import store from './store';
import { getToken } from '@/utils/auth';
import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.beforeEach(async (to, from, next) => {
    const hasToken = getToken();
    if (hasToken) {
        if (!store.getters.user) {
            try {
                await store.dispatch('auth/getInfo');
            } catch (err) {
                //
            }
        }
        if (to.path === '/logout') {
            await store.dispatch('auth/logout')
            next('/');
        }
        next();
    } else {
        next();
    }
});

// eslint-disable-next-line no-unused-vars
router.afterEach(async (to, from, next) => {
    NProgress.done();
});